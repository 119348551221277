import { FANTASY_ENDPOINTS } from '../utils/restEndpoints'
import {
  QueryParams,
  callGraphQL,
  makeAPIRequest,
} from '../utils/request'
import {
  createFantasyGroupMutation,
  deleteFantasyGroupMutation,
  deleteUserFromFantasyGroupMutation,
  joinFantasyGroupMutation,
  joinFantasyGroupToCompetition,
  leaveFantasyGroupMutation,
  submitFantasyTeamMutation,
  updateFantasyGroupMutation,
} from './fantasy.mutations'
import {
  fantasyChallengerQuery,
  fantasyGroupsQuery,
  fantasySingleGroupQuery,
  FANTASY_USER_QUERY,
  fantasyGroupCompetitionByIdQuery,
  generateTeamAIQuery,
  fantasyUserGroupsQuery,
  allFantasyGroupCompetitions,
} from './fantasy.queries'
import {
  FantasyConfigRes,
  FantasyGroup,
  FantasyGroupRes,
  FantasyGroupsRes,
  FantasyJoinGroupRes,
  FantasyUser,
  FantasyUserRes,
  SubmitFantasyTeamRes,
  CreatedGroup,
  CreatedGroupRes,
  AcceptChallenge,
  SingleFantasyGroupCompetition,
  FantasyGroupCompetitionRes,
  GenerateAITeamRes,
  CondensedAIPlayer,
  DeleteGroupRes,
  FantasyUserGroupsRes,
  AllFantasyGroupCompetitionRes,
} from './fantasy.types'
import { getFirAuth } from '../firebase'
import { UpdateUserParams } from '../types/users.types'
import { SeasonType } from '../../Context/SeasonContext/SeasonContext'
import { SEASON } from '../../Configs/config'

// REST API //
/**
 * Fetch config file for PLL Nation
 */
export const fetchFantasyConfig =
  async (): Promise<FantasyConfigRes | null> => {
    try {
      const configRes = await makeAPIRequest(
        FANTASY_ENDPOINTS.GET_CONFIG,
        {
          method: 'GET',
          apiVersion: '2',
          headerParams: {
            Authorization:
              `Bearer ${process.env.REACT_APP_API_ACCESS_KEY}` || '',
          },
          queryParams: {
            products: 'fantasy',
          },
        }
      )
      if (!configRes?.json) return null
      return configRes.json
    } catch (err: unknown) {
      console.error(err)
      return null
    }
  }

// QUERIES //

/**
 * Fetch fantasy user by year
 */
export const fetchFantasyUser = async (
  userId: string,
  week: number,
  year: number,
  segment: SeasonType
): Promise<FantasyUser | null> => {
  try {
    let fantasyRes = await callGraphQL<FantasyUserRes>(
      FANTASY_USER_QUERY,
      {
        userId,
        year,
        week,
        segment,
      }
    )
    if (!fantasyRes?.fantasyUserPicks) return null
    return fantasyRes.fantasyUserPicks
  } catch (error) {
    return null
  }
}

export const fetchFantasyChallenger = async (
  userId: string,
  week: number,
  year: number,
  segment: SeasonType
): Promise<FantasyUser | null> => {
  try {
    let usr = await callGraphQL<FantasyUserRes>(
      fantasyChallengerQuery,
      {
        userId,
        week,
        year,
        segment,
      }
    )
    if (!usr?.fantasyUserPicks) return null
    return usr.fantasyUserPicks
  } catch (error) {
    return null
  }
}

/**
 * Fetch all fantasy public groups
 */
export const fetchFantasyGroupsByYear = async (
  year: number
): Promise<FantasyGroup[] | null> => {
  try {
    let groupsRes = await callGraphQL<FantasyGroupsRes>(
      fantasyGroupsQuery,
      {
        year: year,
      }
    )
    if (!groupsRes?.allFantasyGroups) return null
    return groupsRes.allFantasyGroups
  } catch (error) {
    return null
  }
}

/**
 * Fetch single fantasy group
 */
export const fetchFantasyGroupById = async (
  groupId: number,
  week: number,
  sortBy = 'weekly'
): Promise<FantasyGroup | null> => {
  try {
    let groupRes = await callGraphQL<FantasyGroupRes>(
      fantasySingleGroupQuery,
      {
        groupId,
        week,
        sortBy,
      }
    )
    if (!groupRes?.fantasyGroup) return null
    return groupRes.fantasyGroup
  } catch (error) {
    return null
  }
}

/**
 * Fetch single fantasy group competition
 */
export const fetchFantasyGroupCompetitionById = async (
  competitionId: number,
  limit: number = 25,
  page?: number
): Promise<SingleFantasyGroupCompetition | null> => {
  try {
    let groupCompRes = await callGraphQL<FantasyGroupCompetitionRes>(
      fantasyGroupCompetitionByIdQuery,
      {
        competitionId,
        limit,
        page: page || 1,
      }
    )
    if (!groupCompRes?.fantasyGroupCompetition) return null
    return groupCompRes.fantasyGroupCompetition
  } catch (error) {
    return null
  }
}

/**
 * Fetch user's fantasy groups per year and week
 */
export const fetchFantasyUserGroups = async (
  userId: string,
  week: number,
  year: number
): Promise<FantasyGroup[] | null> => {
  try {
    let fantasyRes = await callGraphQL<FantasyUserGroupsRes>(
      fantasyUserGroupsQuery,
      {
        userId,
        year,
        week,
      }
    )
    if (!fantasyRes?.fantasyUserPicks) return null
    return fantasyRes.fantasyUserPicks.groups
  } catch (error) {
    return null
  }
}

/**
 * Fetch all fantasy group competitions
 */
export const fetchAllFantasyGroupCompetitions = async (
  year: number,
  limit?: number
): Promise<SingleFantasyGroupCompetition[] | null> => {
  try {
    let groupCompRes = await callGraphQL<AllFantasyGroupCompetitionRes>(
      allFantasyGroupCompetitions,
      {
        year,
        limit,
      }
    )
    if (!groupCompRes?.allFantasyGroupCompetitions) return null
    return groupCompRes.allFantasyGroupCompetitions
  } catch (error) {
    return null
  }
}

// MUTATIONS //
/**
 * Fetch single fantasy group
 */
export const joinFantasyGroupById = async (
  groupId: number,
  userId: string
): Promise<{ success: boolean; error: string | null }> => {
  try {
    let joinRes = await callGraphQL<FantasyJoinGroupRes>(
      joinFantasyGroupMutation,
      {
        groupId,
        userId,
      },
      1 //Gamification is true
    )
    if (!joinRes?.joinFantasyGroup) {
      return { success: false, error: 'Trouble joining group' }
    }
    return { success: true, error: null }
  } catch (error: unknown) {
    console.log('Error join Fantasy Group By Id: ', error)
    return { success: false, error: 'Trouble joining group' }
  }
}

export const submitFantasyTeam = async (
  userId: string,
  week: number,
  playerInfoIds: number[]
): Promise<boolean> => {
  try {
    let submitRes = await callGraphQL<SubmitFantasyTeamRes>(
      submitFantasyTeamMutation,
      {
        userId,
        week,
        playerInfoIds,
      },
      1 //Gamification is true
    )
    if (submitRes?.submitFantasyTeam) {
      return true
    }
    //success snack bar
    return false
  } catch (error) {
    console.error(error)
    return false
  }
}

export const createFantasyGroup = async (
  group: CreatedGroup
): Promise<FantasyGroup | null> => {
  try {
    let groupRes = await callGraphQL<CreatedGroupRes>(
      createFantasyGroupMutation,
      {
        input: group,
      },
      1 //Gamification is true
    )
    if (!groupRes?.createFantasyGroup) return null
    return groupRes.createFantasyGroup
  } catch (error) {
    console.error(error)
    return null
  }
}

export const updateFantasyUser = async (
  userId: string,
  data: UpdateUserParams
) => {
  const usr = (await getFirAuth())?.currentUser
  let headerParams: QueryParams = { userId: userId }
  if (usr) {
    const token = await usr.getIdToken()
    headerParams.Authorization = `Bearer ${token}`
  }
  let body = JSON.stringify(data)
  try {
    const response = await makeAPIRequest(
      FANTASY_ENDPOINTS.UPDATE_FANTASY_USER,
      {
        method: 'PUT',
        apiVersion: '2',
        headerParams: headerParams,
        body: body,
      }
    )
    if (response?.status === 200) {
      return true
    }
    return false
  } catch (err: unknown) {
    console.error(err)
    return false
  }
}

export const acceptChallengeInvite = async (
  userId: string,
  data: AcceptChallenge
) => {
  const usr = (await getFirAuth())?.currentUser
  let headerParams: QueryParams = { userId: userId }
  if (usr) {
    const token = process.env.REACT_APP_API_ACCESS_KEY
    headerParams.Authorization = `Bearer ${token}`
  }
  let body = JSON.stringify(data)
  try {
    const response = await makeAPIRequest(
      FANTASY_ENDPOINTS.ACCEPT_CHALLENGER,
      {
        method: 'POST',
        apiVersion: '2',
        headerParams: headerParams,
        body: body,
      }
    )
    if (response?.status === 200) {
      return true
    }
    return false
  } catch (err: unknown) {
    console.error(err)
    return false
  }
}

export const generateFantasyTeamWithAI = async (
  week: number
): Promise<CondensedAIPlayer[] | []> => {
  let year = SEASON
  try {
    let teamRes = await callGraphQL<GenerateAITeamRes>(
      generateTeamAIQuery,
      {
        week,
        year,
      },
      1 //Gamification is true
    )
    if (!teamRes?.generateFantasyTeamAI) return []
    return teamRes.generateFantasyTeamAI
  } catch (error) {
    console.error(error)
    return []
  }
}

export const removeUserFromGroup = async (
  userId: string,
  groupId: number,
  deletedUserId: string
): Promise<boolean> => {
  try {
    let removeUserRes = await callGraphQL<any>(
      deleteUserFromFantasyGroupMutation,
      {
        input: {
          deletedUserId: deletedUserId,
          groupId: groupId,
          userId: userId,
        },
      }
    )
    if (removeUserRes.deleteUserFromFantasyGroup) {
      return true
    }
    return false
  } catch (error) {
    console.error(error)
    return false
  }
}

export const deleteGroup = async (
  userId: string,
  groupId: number
): Promise<DeleteGroupRes | null> => {
  try {
    let deleteGroupRes = await callGraphQL<any>(
      deleteFantasyGroupMutation,
      {
        input: {
          groupId: groupId,
          userId: userId,
        },
      }
    )
    if (deleteGroupRes) {
      return deleteGroupRes
    }
    return null
  } catch (error) {
    console.error(error)
    return null
  }
}

export const userLeaveFantasyGroup = async (
  userId: string,
  groupId: number
): Promise<boolean> => {
  try {
    let userLeaveRes = await callGraphQL<any>(
      leaveFantasyGroupMutation,
      {
        input: {
          groupId: groupId,
          userId: userId,
        },
      }
    )
    if (userLeaveRes.leaveFantasyGroup.id) {
      return true
    }
    return false
  } catch (error) {
    console.error(error)
    return false
  }
}

export const updateFantasyGroup = async (
  userId: string,
  groupId: number,
  groupName: string,
  description: string
): Promise<boolean> => {
  try {
    let updateGroupRes = await callGraphQL<any>(
      updateFantasyGroupMutation,
      {
        input: {
          userId: userId,
          groupId: groupId,
          groupName: groupName,
          description: description,
        },
      }
    )
    if (updateGroupRes.updateFantasyGroup.id) {
      return true
    }
    return false
  } catch (error) {
    console.error(error)
    return false
  }
}

export const joinFantasyGroupCompetition = async (
  competitionId: number,
  groupId: number,
  userId: string
): Promise<boolean> => {
  try {
    let joinCompRes = await callGraphQL<any>(
      joinFantasyGroupToCompetition,
      {
        input: {
          userId: userId,
          groupId: groupId,
          competitionId: competitionId,
        },
      }
    )
    if (joinCompRes.joinFantasyGroupCompetitions) {
      return true
    }
    return false
  } catch (error: any) {
    console.error(error.message)
    return false
  }
}
