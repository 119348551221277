import { styled } from '@mui/system'
import { colors } from '../consts'
import { lazy } from 'react'

export const TextField = lazy(() =>
  import('@mui/material').then((module) => ({
    default: module.TextField,
  }))
)

export type StyleProps = {
  uiTheme?: 'light' | 'dark' | 'gray'
  className?: string
  borderWidth?: number
  minWidth?: number | string
  height?: number | string
  vertMargins?: number
  sideMargins?: number
  fullWidth?: boolean
}

const shouldForwardProp = (prop: string) => prop !== 'uiTheme'

export const InputStyle = styled(TextField, {
  shouldForwardProp,
})<StyleProps>(
  ({
    uiTheme,
    borderWidth,
    minWidth,
    height,
    fullWidth,
    vertMargins,
    sideMargins,
    theme,
  }) => ({
    margin: theme.spacing(vertMargins || 2, sideMargins || 2),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(
        vertMargins ? vertMargins * 0.5 : 1,
        sideMargins ? sideMargins * 0.5 : 1
      ),
    },
    '& .MuiInputBase-root': {
      // This targets input field
      background:
        uiTheme === 'dark'
          ? colors.darkBackground
          : uiTheme === 'gray'
          ? colors.gray.gray60
          : colors.white,
      borderColor:
        uiTheme === 'dark'
          ? colors.gray.gray75
          : uiTheme === 'gray'
          ? colors.gray.gray40
          : colors.gray.gray20,
      borderWidth: borderWidth || 1,
      height: height || 'auto',
      minWidth: fullWidth ? '100%' : minWidth ? minWidth : 'auto',
      '&:focus': {
        borderWidth: borderWidth ? borderWidth + 1 : 2,
        borderColor:
          uiTheme === 'dark'
            ? colors.gray.gray75
            : uiTheme === 'gray'
            ? colors.gray.gray40
            : colors.gray.gray20,
      },
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused': {
        // This changes the border color when the TextField is focused
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: borderWidth ? borderWidth + 1 : 2,
          borderColor:
            uiTheme === 'dark'
              ? colors.gray.gray75
              : uiTheme === 'gray'
              ? colors.gray.gray40
              : colors.gray.gray20,
        },
      },
    },
    '& .MuiFormLabel-root': {
      // This targets the label of the TextField
      color:
        uiTheme === 'dark'
          ? colors.gray.gray40
          : uiTheme === 'gray'
          ? colors.black
          : colors.text,
    },
    '& .MuiFormHelperText-root': {
      // This targets the helper text
      color:
        uiTheme === 'dark'
          ? colors.gray.gray60
          : uiTheme === 'gray'
          ? colors.white
          : colors.gray.gray70,
    },
    '& .MuiInputBase-input': {
      // This targets the text inside the input
      color:
        uiTheme === 'dark'
          ? colors.gray.gray20
          : uiTheme === 'gray'
          ? colors.gray.gray90
          : colors.text,
      '&:focus': {
        color:
          uiTheme === 'dark'
            ? colors.gray.gray20
            : uiTheme === 'gray'
            ? colors.gray.gray90
            : colors.text,
      },
    },
  })
)
